body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* layout */
/*
body {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 15px;
}
*/

.row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.col {
    flex: 1;
	padding: 15px;
}

.row > .col:first-child {
	border-right: 1px solid #ccc;
	max-width: 400px;
}

.row > .col:last-child {
	background: #fafafa;
}

.flex {
	display: flex;
}

.wrap {
	flex-wrap: wrap;
}

.column {
	flex-direction: column;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-space-between {
	justify-content: space-between;
	padding-top: 6px;
	padding-bottom: 6px;
}

.text-center {
	text-align: center;
}



/* apps */
.list-header {
	font-weight: bold;
	color: lightslategray;
}

.list-item {
	padding: 3px 5px 3px 5px;
	background-color: lightgray;
	color: black;
	border-radius: 8px;
	font-weight: normal;
	margin-left: 2px;
	margin-right: 2px;
}

.avg-rating {
	color: #6b6b6b;
	margin-left: 5px;
}

.stars {
	color: gold;
}

.location {
	color: salmon;
	margin-right: 5px;
}

.book-title {
	white-space: normal;
	margin-top: 4px;
}

.book-title-card {
	white-space: normal;
	margin-top: 4px;
	max-height: 45px;
}

.book-image {
	width: 175px;
	background-size: cover;
}

.book-header {
	font-weight: bold;
	margin-bottom: 5px;
	font-size: 150%;
	color: slategray;
}

.book-header a {
	text-decoration: none;
	color: slategray;
}

.book-content {
	background: white;
	margin: 10px 0;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.book-col {
	display: flex; 
	flex-direction: row;
}

.book-col-res {
	display: flex;
	flex-direction: column;
	width: 60%;
	text-align: center;
	padding-left: 1%;
	padding-right: 1%;
}

.book-col-fil {
	display: flex; 
	flex-direction: column; 
	width: 40%;
	padding-left: 1%;
	padding-right: 1%;
}

.div-hr {
	color: gray;
	background-color: gray;
	height: 3px;
	margin: 5px 5px 5px 5px;
	width: 95%;
	border-radius: 25px;
	display: block;
}

.hidden-button {
	padding: 5px;
	margin-top: 10px;
	margin-bottom: 15px;
	font-weight: bold;
	font-size: 120%;
	width: 75px;
}
    
.hidden-small {
	display: none;
}

@media all and (max-width: 850px) {
	.row {
		flex-direction: column;
	}

	.row > .col:first-child {
		border-right: none;
		max-width: none;
	}

	.book-col {
		display: block;
	}

	.book-col-res {
		width: 100%;
	}

	.book-col-fil {
		width: 100%;
	}
	
	.hidden-small {
		display: unset;
	}
}

